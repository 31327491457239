import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BaseSharedModule } from '../../../../_base-shared/base-shared.module';
import { ServerErrorsComponent } from './components/server-errors/server-errors.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports:      [
    BaseSharedModule,
    NgxMatSelectSearchModule,
    MatTabsModule,
    MatExpansionModule,
    MatStepperModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [
    ServerErrorsComponent,
  ],
  exports:      [
    BaseSharedModule,
    ServerErrorsComponent,
    MatStepperModule,
    MatExpansionModule,
    MatTabsModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
})
export class SharedModule {
}
